.address {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.tooltip-underline {
  text-decoration: underline;
  text-decoration-style: dotted;
}
